/*Onbording ***********************************************************************************************/
:root {
  --phone: 480px;
  --tablet: 768px;
  --desktop: 980px;
  --widescreen: 1200px;
  --color-white: #fff;
  --color-offwhite: #ede7d8;
  --color-black: #000;
  --color-navy: #354253;
  --color-dark: #23272a;
  --color-grey-1: #333;
  --color-grey-2: #4f4f4f;
  --color-grey-3: #828282;
  --color-grey-4: #bdbdbd;
  --color-grey-5: #e0e0e0;
  --color-grey-6: #f2f2f2;
  --color-grey-7: #f9f9f9;
  --color-blue: #39f;
  --color-blue-dark: #6953e7;
  --color-red: #eb5757;
  --color-green-1: #219653;
  --color-yellow: #f2c94c;
  --color-orange: #f2994a;
  --color-orange-100: #0E3279;
  --color-orange-tint-80: #cd8249;
  --color-orange-tint-70: #d18d58;
  --color-orange-tint-50: #daa277;
  --color-orange-tint-40: #deac86;
  --color-orange-tint-30: #e2b695;
  --color-orange-tint-20: #e6c1a4;
  --color-orange-tint-10: #eacbb3;
  --color-orange-tint-6: #f3e0d2;
  --color-orange-tint-4: #f7eae1;
  --color-orange-tint-2: #fbf5f0;
  --color-orange-tint-1: #fffcf9;
  --color-orange-shade-100: #0E3279;
  --color-orange-shade-90: #af6730;
  --color-orange-shade-80: #a15f2c;
  --color-orange-shade-70: #945728;
  --color-orange-shade-60: #864f25;
  --color-orange-shade-50: #794721;
  --color-orange-shade-40: #794721;
  --color-orange-shade-30: #5e371a;
  --color-orange-shade-20: #512f16;
  --color-orange-shade-10: #432812;
  --color-orange-shade-8: #36200f;
  --color-orange-shade-6: #28180b;
  --color-orange-shade-4: #1b1007;
  --color-orange-shade-2: #0d0804;
  --font-sans-serif: 'Roboto', sans-serif;
  --font-serif: 'Times New Roman', Times, Baskerville, Georgia, serif;
  --base-background-color: var(--color-white);
  --base-font-family: var(--font-sans-serif);
  --base-font-size: var(--text);
  --base-line-height: 1.42;
  --base-text-color: var(--color-navy);
  --base-border-radius: 0.4rem;
  --base-min-width: 320px;
  --gap: 15px;
  --base-link-color: var(--color-orange-100);
  --btn-color: var(--color-white);
  --btn-background: var(--color-orange-100);
  --btn-background-active: var(--color-white);
  --btn-height: 2.5em;
  --btn-radius: 0.333em;
  --btn-padding: 0.5em 1em;
  --btn-font-size: 1.2rem;
  --btn-font-family: var(--font-sans-serif);
  --form-font-family: var(--font-sans-serif);
  --form-font-size: 1.4rem;
  --form-element-padding: 0.35714285714em 0.57142857142em;
  --form-element-border-color: var(--color-white);
  --form-element-focus-border-color: var(--color-orange-100);
  --placeholder-color: #808084;
  --headings-font-family: var(--font-sans-serif);
  --headings-color: var(--base-text-color);
  --headings-sizes-h1: 2.4rem;
  --headings-sizes-h2: 2rem;
  --headings-sizes-h3: 1.8rem;
  --headings-sizes-h4: 1.6rem;
  --headings-sizes-h5: 1.4rem;
  --headings-sizes-h6: 1.2rem;
  --text: 1.4rem;
  --h-height-top: 5.2rem;
  --h-height-bottom: 4rem;
  --h-height: calc(var(--h-height-bottom) + var(--h-height-top));
  --color-bluewood: #283b49;
}

.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

*,
:before,
:after {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
}

::-webkit-scrollbar-track {
  background-color: var(--color-orange-tint-1);
  border-radius: 0.4rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background: var(--color-grey-5);
}

html {
  font-size: 62.5%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--base-text-color);
  background: var(--base-background-color);
  font: var(--base-font-size) / var(--base-line-height)
      var(--base-font-family);
  min-width: var(--base-min-width);
  font-weight: 400;
  overflow-anchor: none;
}

img {
  max-width: 100%;
  height: auto;
}

.gm-style img {
  max-width: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  font-weight: 700;
  margin: 0;
  line-height: 1.5;
}

h1:not(:last-child),
.h1:not(:last-child),
h2:not(:last-child),
.h2:not(:last-child),
h3:not(:last-child),
.h3:not(:last-child),
h4:not(:last-child),
.h4:not(:last-child),
h5:not(:last-child),
.h5:not(:last-child),
h6:not(:last-child),
.h6:not(:last-child),
.h:not(:last-child) {
  margin-bottom: 3.2rem;
}

h1,
.h1 {
  font-size: var(--headings-sizes-h1);
}

h2,
.h2 {
  font-size: var(--headings-sizes-h2);
}

h3,
.h3 {
  font-size: var(--headings-sizes-h3);
}

h4,
.h4 {
  font-size: var(--headings-sizes-h4);
}

h5,
.h5 {
  font-size: var(--headings-sizes-h5);
  font-weight: 800;
}

h6,
.h6 {
  font-size: var(--headings-sizes-h6);
}

p {
  margin: 0;
}

a {
  color: inherit;
}

a:focus {
  text-decoration: none;
}

strong {
  font-weight: 600;
}

.yellow {
  color: var(--color-yellow) !important;
}

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

// input[type='text'],
// input[type='tel'],
// input[type='email'],
// input[type='search'],
// input[type='password'],
// textarea {
//     -webkit-appearance: none;
//     appearance: none;
//     box-sizing: border-box;
//     font-family: var(--form-font-family);
//     border-radius: 0;
//     border: 0.1rem solid var(--form-element-border-color);
//     padding: var(--form-element-padding);
//     outline: none;
// }

input[type='text']:focus,
input[type='tel']:focus,
input[type='email']:focus,
input[type='search']:focus,
input[type='password']:focus,
textarea:focus {
  border-color: var(--form-element-focus-border-color);
}

input[type='text']::-webkit-input-placeholder,
input[type='tel']::-webkit-input-placeholder,
input[type='email']::-webkit-input-placeholder,
input[type='search']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: var(--placeholder-color);
}

input[type='text']::-moz-placeholder,
input[type='tel']::-moz-placeholder,
input[type='email']::-moz-placeholder,
input[type='search']::-moz-placeholder,
input[type='password']::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: var(--placeholder-color);
}

input[type='text']:-moz-placeholder,
input[type='tel']:-moz-placeholder,
input[type='email']:-moz-placeholder,
input[type='search']:-moz-placeholder,
input[type='password']:-moz-placeholder,
textarea:-moz-placeholder {
  color: var(--placeholder-color);
}

input[type='text']:-ms-input-placeholder,
input[type='tel']:-ms-input-placeholder,
input[type='email']:-ms-input-placeholder,
input[type='search']:-ms-input-placeholder,
input[type='password']:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: var(--placeholder-color);
}

input[type='text'].placeholder,
input[type='tel'].placeholder,
input[type='email'].placeholder,
input[type='search'].placeholder,
input[type='password'].placeholder,
textarea.placeholder {
  color: var(--placeholder-color);
}

select {
  border-radius: 0;
}

textarea {
  resize: vertical;
  vertical-align: top;
}

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  cursor: pointer;
  font-family: var(--form-font-family);
}

.icon {
  fill: currentColor;
  stroke: currentColor;
  color: inherit;
  width: 100%;
  height: 100%;
}

.logo {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: inherit;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 3.075rem;
}

.logo picture {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: inherit;
  flex-wrap: nowrap;
}

.logo img {
  width: 100%;
  height: auto;
}

.image {
  position: relative;
  width: 100%;
  height: auto;
}

.image picture {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: inherit;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.image img {
  width: 100%;
}

.image--cover {
  min-height: 100%;
  height: auto;
}

.image--cover img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image--1-1 picture {
  padding-top: 100%;
}

.image--3-2 picture {
  padding-top: 66.66%;
}

.image--3-4 picture {
  padding-top: 75%;
}

.image--4-5 picture {
  padding-top: 120%;
}

.image--16-9 picture {
  padding-top: 56.25%;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 1.6rem;
  height: 1.6rem;
  background: transparent;
  transition: all 0.3s ease-in-out;
  color: var(--color-white);
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: var(--color-navy);
}

.swiper-button-prev .icon,
.swiper-button-next .icon {
  width: 1rem;
  height: 1.6rem;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}

.swiper-button-prev {
  justify-content: flex-start;
  left: 0.6rem;
}

.swiper-button-next {
  justify-content: flex-end;
  right: 0.6rem;
}

.swiper-pagination {
  bottom: 0.6rem !important;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullet {
  width: 1.6rem;
  height: 0.4rem;
  border-radius: 0.4rem;
  background-color: var(--color-white);
  margin: 0 0.4rem;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: var(--color-yellow);
}

.checkbox {
  position: relative;
  width: 100%;
  width: 100%;
}

.checkbox input {
  position: absolute;
  left: 100%;
  opacity: 0;
  width: 0;
  height: 0;
}

.checkbox label {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
}

.checkbox label:hover {
  cursor: pointer;
}

.checkbox--no-decor .checkbox-text {
  position: relative;
  width: 100%;
  border-radius: 10rem;
  padding: 0.8rem 1.2rem;
  background: var(--color-grey-7);
  color: var(--color-grey-3);
  transition: all 0.3s ease-in-out;
  border: 0.1rem solid var(--color-grey-7);
}

.checkbox--no-decor .checkbox-text strong {
  font-weight: 700;
  color: var(--color-navy);
}

.checkbox--no-decor .checkbox-text:after {
  content: '';
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  -webkit-mask-size: contain;
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  background-color: currentColor;
  -webkit-mask-image: url(../images/icons/icon-close.svg);
  mask-image: url(../images/icons/icon-close.svg);
  opacity: 0;
  visibility: hidden;
  right: 0.8rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transition: all 0.3s ease-in-out;
}

.checkbox--no-decor label:hover .checkbox-text {
  border-color: var(--color-navy);
  color: var(--color-navy);
}

.checkbox--no-decor label:hover .checkbox-text:after {
  background-color: #56ccf2;
}

.checkbox--no-decor input:checked ~ .checkbox-text {
  border-color: var(--color-navy);
  background: var(--color-navy);
  color: var(--color-white);
}

.checkbox--no-decor input:checked ~ .checkbox-text strong {
  color: inherit;
}

.checkbox--no-decor input:checked ~ .checkbox-text:after {
  opacity: 1;
  visibility: visible;
}

.checkbox--tabs {
  position: relative;
  display: flex;
}

.checkbox--tabs .checkbox-text:after {
  display: none;
}

.checkbox--tabs label:nth-child(1) .checkbox-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.checkbox--tabs label:nth-child(2) .checkbox-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.checkbox--toggler label {
  height: 100%;
}

.checkbox--toggler .checkbox-toggler {
  position: relative;
  width: 3.6rem;
  min-width: 3.6rem;
  height: 2rem;
  border-radius: 10rem;
  background: var(--color-grey-5);
  transition: all 0.3s ease-in-out;
}

.checkbox--toggler .checkbox-toggler span {
  position: absolute;
  display: inline-block;
  left: 0.6rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 0.8rem;
  height: 1.2rem;
  border-radius: 10rem;
  background: var(--color-white);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.checkbox--toggler input:checked ~ .checkbox-toggler {
  background: var(--color-green-1);
}

.checkbox--toggler input:checked ~ .checkbox-toggler span {
  left: 2.2rem;
}

.select {
  position: relative;
  width: 100%;
}

.select:not(:last-child) {
  margin-bottom: 0.8rem;
}

.select select {
  position: relative;
  width: 100%;
  height: 3.2rem;
  padding: 0.2rem 1.2rem;
  border: 0.1rem solid var(--color-grey-4);
  color: var(--color-grey-3);
  -webkit-appearance: none;
  appearance: none;
  background: url(../images/icons/icon-polygon--down.svg), #fff;
  background-repeat: no-repeat;
  background-position: center right 1.2rem;
  background-size: 0.8rem auto;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// .content {
//     flex: 1 0 auto;
//     flex-direction: column;
//     padding-top: var(--h-height);
// }

.section {
  --section-pd: 0 0;
  --section-bg: transparent;
  position: relative;
  width: 100%;
  padding: var(--section-pd);
  background: var(--section-bg);
  overflow: hidden;
}

.container {
  position: relative;
  z-index: 1;
  padding: 0 var(--gap);
}

.row {
  margin: 0 calc(-1 * var(--gap));
}

.row > div {
  padding: 0 var(--gap);
}

.header {
  --h-pos: fixed;
  --h-z: 10;
  --h-pd-top: 1rem 0.8rem 1rem 2.4rem;
  --h-pd-bottom: 0.5rem 1.6rem;
  --h-fz: inherit;
  --h-bg: var(--color-white);
  position: var(--h-pos);
  width: 100%;
  background: var(--h-bg);
  font-size: var(--h-fz);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.12);
  z-index: var(--h-z);
  height: var(--h-height);
}

.header_block {
  position: relative;
}

.header_block-top {
  padding: var(--h-pd-top);
  height: var(--h-height-top);
}

// .header_block-bottom {
//     padding: var(--h-pd-bottom);
//     height: var(--h-height-bottom);
//     background: var(--color-orange-tint-2);
//     overflow: hidden;
// }

.header_container {
  --h-c-gap: 1.6rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: inherit;
  flex-wrap: nowrap;
  gap: var(--h-c-gap);
}

.header_search {
  flex: 1;
}

.header_menu {
  position: relative;
}

.header_submenu {
  position: relative;
  overflow: auto;
  padding-bottom: 17px;
}

.header_submenu-scroll-button {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 2;
  width: 2.4rem;
  height: 3rem;
  background: var(--color-white);
  border: none;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  color: var(--color-grey-3);
  opacity: 0;
  pointer-events: none;
}

.header_submenu-scroll-button.visible {
  opacity: 1;
  pointer-events: all;
}

.header_submenu-scroll-button:hover {
  color: var(--color-orange-100);
}

.header_submenu-scroll-button .icon {
  width: 0.8rem;
  height: 0.8rem;
}

.header_submenu-scroll-button-next {
  border-radius: 0 0.4rem 0.4rem 0;
  right: 0.8rem;
}

.header_submenu-scroll-button-prev {
  border-radius: 0.4rem 0 0 0.4rem;
  left: 0.8rem;
}

.header_submenu-content {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - var(--h-height));
  overflow: auto;
  opacity: 0;
  visibility: hidden;
}

.header_submenu-content.open {
  opacity: 1;
  visibility: visible;
}

.header_submenu-content-item {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 3.2rem;
  padding: 2.4rem;
  opacity: 0;
  visibility: hidden;
  background: var(--color-white);
  transition: all 0.3s ease-in-out;
  z-index: 0;
}

.header_submenu-content-item.active {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

.header_submenu-category {
  position: relative;
  font-size: 1.2rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.header_submenu-category li {
  position: relative;
  display: block;
}

.header_submenu-category li:not(:last-child) {
  margin-bottom: 1rem;
}

.header_submenu-category a {
  position: relative;
  text-decoration: none;
  color: var(--color-grey-3);
  transition: color 0.3s ease-in-out;
}

.header_submenu-category a.active,
.header_submenu-category a:hover {
  color: var(--color-navy);
  font-weight: 700;
}

.header_submenu-category-wrap {
  position: relative;
  width: calc((100% - 5 * 3.2rem) / 6);
}

.header_submenu-category-title {
  position: relative;
  font-weight: 700;
  font-size: 1.4rem;
  min-height: 4rem;
  margin-bottom: 0.8rem;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.24);
}

.header_actions {
  --h-a-gap: 1.6rem;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: inherit;
  flex-wrap: nowrap;
  gap: var(--h-a-gap);
  padding-left: 0.8rem;
}

.header_action {
  --h-a-color: var(--color-grey-3);
  --h-a-color-active: var(--color-navy);
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  transition: color 0.3s ease-in-out;
  border: none;
  padding: 0;
  background: transparent;
}

.header_action-mails,
.header_action-notifications,
.header_action-cart {
  width: 3.2rem;
  height: 3.2rem;
  color: var(--h-a-color);
}

.header_action-mails:hover,
.header_action-notifications:hover,
.header_action-cart:hover {
  cursor: pointer;
  color: var(--h-a-color-active);
}

.header_action .icon {
  width: 2.4rem;
  height: 2.4rem;
}

.header_action .text {
  position: absolute;
  min-width: 1.8rem;
  width: 1.8rem;
  border-radius: 1.8rem;
  height: 1.8rem;
  background: var(--color-red);
  color: var(--color-white);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  font-weight: 700;
  font-size: 1.2rem;
  right: 0;
  top: -0.6rem;
  overflow: hidden;
  opacity: 0;
}

.header_action .text.visible {
  opacity: 1;
}

.header_action-notifications .dropdown_body {
  left: auto;
  right: 0;
  width: 24rem;
  padding: 0.8rem;
  background: var(--color-grey-7);
  max-height: 32rem;
  overflow: auto;
}

.header_action-notifications .dropdown_inner {
  padding: 0;
}

.header_action-notifications .dropdown_inner ul {
  text-align: left;
  font-size: 1.2rem;
  letter-spacing: 0.02em;
  font-weight: 400;
}

.header_action-notifications .dropdown_inner ul li {
  color: #2b4053;
  padding: 0.8rem 1.2rem;
  background: var(--color-white);
}

.header_action-notifications .dropdown_inner ul li:not(:last-child) {
  margin-bottom: 0.8rem;
}

.header_action-notifications .dropdown_inner ul li span {
  color: #d46f2c;
}

.footer {
  --f-bg: var(--color-ghostwhite);
  --f-fz: inherit;
  --f-pd-top: 17.6rem 0 3rem 0;
  --f-pd-bottom: 3.2rem 0;
  position: relative;
}

.footer_menu {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 1rem;
}

.footer_menu li {
  position: relative;
  display: block;
  width: 100%;
}

.footer_menu a {
  position: relative;
  display: inline-block;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footer_menu a:focus,
.footer_menu a:hover,
.footer_menu a.active {
  color: var(--color-indigo);
}

.footer_menu-wrap {
  position: relative;
}

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: var(--btn-background);
  padding: var(--btn-padding);
  height: var(--btn-height);
  border-radius: var(--btn-radius);
  border: 0.2rem solid var(--btn-background);
  font-size: var(--btn-font-size);
  font-family: var(--btn-font-family);
  font-weight: 700;
  letter-spacing: 0.05em;
  color: var(--btn-color);
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  gap: 1rem;
}

.button:hover {
  background: transparent;
  border-color: var(--btn-background);
  color: var(--btn-background);
}

.button:active {
  background: var(--btn-background-active);
  border-color: var(--btn-background-active);
}

.button-dark {
  --btn-background: var(--color-navy);
  --btn-color: var(--color-white);
}

.button-red {
  --btn-background: var(--color-red);
  --btn-color: var(--color-white);
}

.button-orange {
  --btn-background: var(--color-orange);
  --btn-color: var(--color-white);
}

.button-green {
  --btn-background: var(--color-green-1);
  --btn-color: var(--color-white);
}

.button-white {
  --btn-background: var(--color-white);
  --btn-color: var(--color-grey-3);
  border-color: var(--color-grey-3);
}

.button-white:hover {
  background: var(--color-grey-3);
  border-color: var(--color-grey-3);
}

.button-grey {
  --btn-background: var(--color-grey-6);
  --btn-color: var(--color-navy);
}

.button-grey:hover {
  background: var(--color-navy);
  color: var(--color-white);
  border-color: var(--color-navy);
}

.button-grey-3 {
  --btn-background: var(--color-grey-3);
  --btn-color: var(--color-white);
}

.button-grey-3:hover {
  background: var(--color-grey-3);
  color: var(--color-white);
  border-color: var(--color-grey-3);
}

.button .icon {
  width: 1.6rem;
  height: 1.6rem;
}

.button.large {
  --btn-font-size: 1.4rem;
  --btn-height: 4rem;
}

.button[disabled] {
  --btn-background: var(--color-orange-tint-20);
  --btn-color: var(--color-white);
  pointer-events: none;
}

.button.bordered {
  color: var(--btn-background);
  background-color: transparent;
}

.button.bordered:hover {
  color: var(--btn-color);
  background-color: var(--btn-background);
}

@keyframes loading {
  0% {
      transform: rotate(0);
  }

  100% {
      transform: rotate(270deg);
  }
}

.link-text {
  color: var(--color-orange-100);
  text-decoration: none;
}

.text-dark {
  color: var(--color-dark);
}

.w-100 {
  width: 100%;
}

.onbording {
  max-width: 100%;
  height: 100%;
  position: relative;
  background: #fbf5f0;
}

.ob-head {
  position: relative;
  padding: 2.4rem;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.16);
  height: 10rem;
  background: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.ob-head h1 {
  margin: 0;
  padding: 0;
  color: #283b49;
  letter-spacing: 0.2px;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}

.ob-body .form-wrapper {
  margin: 0 auto;
  text-align: center;
  padding-top: 32px;
  min-width: 576px;
  max-width: 576px;
  width: 100%;
}

.ob-body .form-area {
  border-radius: 8px;
  padding: 24px 24px 32px;
  background: #fff;
  width: 100%;
}

.ob-body .form-input .form-control {
  background: #fffcf9;
  border: 1px solid #f3e0d2;
  border-radius: 4px;
  height: 4rem;
  padding: 1rem 1.2rem;
  display: block;
  width: 100%;
  margin-top: 0.6rem;
  margin-bottom: 1.6rem;
}

.ob-body .form-password {
  position: relative;
}

.ob-body .form-password .form-control {
  background: #fbf5f0;
  border: 1px solid #fbf5f0;
  padding-right: 45%;
}

.ob-body .category-form-input .form-input {
  width: 100%;
  text-align: left;
}

.ob-body .category-form-input {
  display: flex;
  justify-content: space-between;
  gap: 1.8rem;
  align-items: baseline;
  margin-bottom: 1rem;
}

.ob-body svg.icon-eye {
  position: absolute;
  right: 13px;
  width: 2.5rem;
  opacity: 0.64;
  height: 2rem;
  top: 17px;
}

.ob-body svg.icon-eye:hover {
  opacity: 0.5;
}

.pwd-indicator {
  position: absolute;
  right: 50px;
  top: 16px;
  color: #354253;
  letter-spacing: 0.2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.ob-body .check-box {
  text-align: left;
  color: #354253;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.ob-body .check-box a {
  text-decoration: none;
  color: #0E3279;
}

.check-box .checkbox-text:before {
  content: '';
  position: absolute;
  width: 2rem;
  height: 2rem;
  left: 0;
  top: 0.1rem;
  -webkit-mask-size: contain;
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  transition: all 0.15s linear;
  background-color: currentColor;
  -webkit-mask-image: url(../images/icons/icon-check--false.svg);
  mask-image: url(../images/icons/icon-check--false.svg);
}

.check-box .checkbox {
  width: 100%;
  height: 100%;
  align-items: center;
  color: #0E3279;
  cursor: pointer;
  margin: 12px 0;
  display: block;
}

.checkbox-text {
  padding-left: 3rem;
}

.checkbox input {
  color: #7194b5;
}

.ob-body .checkbox span {
  color: #354253;
  line-height: 20px;
}

.check-box input:checked ~ .checkbox-text:before {
  -webkit-mask-image: url(../images/icons/icon-check--true.svg);
  mask-image: url(../images/icons/icon-check--true.svg);
}

.ob-body .button.large {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  height: 48px;
}

.verify_email p {
  color: #512f16;
  letter-spacing: 0.3px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}

.verify_email .button.large {
  font-size: 16px;
  height: 40px;
}

.verify-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  position: relative;
  margin-bottom: 1.6rem;
  gap: 1rem;
}

.verify-title .icon-mail {
  width: 2.5rem;
  height: 2rem;
}

.verify-title h2 {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  letter-spacing: 0.4px;
}

.ob-body .form-input .is-invalid {
  border-color: var(--color-red);
  margin-bottom: 0;
}

.ob-body .invalid-feedback {
  color: var(--color-red);
  display: block;
  margin: 0.4rem 0 1rem;
}

.personalize .p-text {
  color: #354253;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.personalize_selection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1.6rem;
}

.personalize_selection .pse_item {
  background: #fbf5f0;
  border-radius: 4px;
  min-height: 182px;
  padding: 0 12px;
  text-align: center;
  width: 100%;
  margin-top: 2.4rem;
  border: 2px solid #fbf5f0;
}

.personalize_selection .pse_item:hover {
  opacity: 0.8;
  cursor: pointer;
}

.personalize_selection .pse_item.active {
  background: #ffffff;
  border: 2px solid #d18d58;
}

.personalize_selection .pse_item.active h3 {
  color: #354253;
}

.personalize_selection .pse_item svg {
  width: 5.6rem;
  height: 5.6rem;
  stroke: none;
  margin-top: 2.4rem;
}

.personalize_selection .pse_item h3 {
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #808084;
  font-weight: 700;
  margin: 0;
  padding: 0;
  margin-top: 1rem;
  font-size: 14px;
  line-height: 14px;
}

.personalize_selection .pse_item p {
  color: #808084;
  letter-spacing: 0.2px;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 1.6rem;
  margin-top: 1rem;
  line-height: 14px;
}

.info-integrate {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #808084;
  display: block;
  width: 100%;
}

.text-navy {
  color: var(--color-navy);
}

.radiobox-group .radiobox-text:before {
  content: '';
  position: absolute;
  color: #0E3279;
  width: 2rem;
  height: 2rem;
  left: 0;
  top: 0;
  -webkit-mask-size: contain;
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  transition: all 0.15s linear;
  background-color: currentColor;
  -webkit-mask-image: url(../images/icons/icon-radio-false.svg);
  mask-image: url(../images/icons/icon-radio-false.svg);
}

.radiobox-group .radiobox input:checked ~ .radiobox-text:before {
  -webkit-mask-image: url(../images/icons/icon-radio-true.svg);
  mask-image: url(../images/icons/icon-radio-true.svg);
}

.radiobox-group .radiobox {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 50%;
  height: 2.5rem;
  cursor: pointer;
  line-height: 17px;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  width: 50%;
  height: 2.5rem;
  cursor: pointer;
  line-height: 17px;
}

.radiobox-group .radiobox input:checked ~ .radiobox-text {
  color: #354253;
}

.select_info-integrate {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 1rem;
  margin-top: 2rem;
  min-height: 40px;
}

.select_info-integrate .radiobox .radiobox-text {
  border: 1px solid #e6c1a4;
  height: auto;
  padding: 1.2rem 1.2rem 1.2rem 3.8rem;
  width: 100%;
  opacity: 0.7;
}

.select_info-integrate .radiobox input:checked ~ .radiobox-text {
  border: 2px solid #0E3279;
  opacity: 1;
}

.select_info-integrate .radiobox-text:before {
  left: 12px;
  top: 2px;
}

.oh-setting {
  margin-top: 60px;
  justify-content: flex-start;
}

.ob-body .fw-wide {
  min-width: 672px;
  max-width: 672px;
  width: 100%;
}

.ob-body .fw-wide .form-area {
  padding: 0;
  background: transparent;
}

.cs-item {
  min-height: 80px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 1.8rem;
  display: flex;
  white-space: nowrap;
  position: relative;
  padding: 24px;
}

.cs-item .cs-link {
  color: #0E3279;
  letter-spacing: 0.4px;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  text-decoration: none;
  position: absolute;
  right: 30px;
  top: 33px;
}

.cs-item .cs-label {
  color: #283b49;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.cs-item .icon {
  width: 2rem;
  height: 2rem;
  stroke: none;
}

.confirm-setting-area {
  border: 1px solid #fff;
}

.confirm-setting-area:hover {
  border: 1px solid #d18d58;
  border-radius: 4px;
}

.confirmation-window .popup_content-header svg.icon {
  width: 3rem;
  height: 2.5rem;
  stroke: none;
  margin: 0;
  padding-top: 1px;
  margin-right: 10px;
}

.confirmation-window .popup_content-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.confirmation-window .popup_content-header .h1 {
  color: #eb5757;
}

.confirmation-window div.popup-close {
  right: 3rem;
  top: 3rem;
}

.confirmation-window .popup_content-footer {
  flex-direction: column;
  text-align: center;
}

.confirmation-window a.button-link {
  font-weight: 400;
  font-size: 18px;
  margin-top: 1rem;
  line-height: 18px;
  color: #eb5757;
  text-decoration: none;
}

.confirmation-window .popup_content-body p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.form-area > .cs-link-main {
  display: flex;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  width: 100%;
  padding: 24px;
}

.cs-link-main:hover {
  cursor: pointer;
}

.fit-d-flex {
  display: flex;
  gap: 1rem;
}

.form-input-two a {
  width: 50%;
}

.not-supported-area {
  padding: 24px 48px 24px 24px;
  gap: 100px;
  width: 1152px;
  height: 240px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
}

.not-supported-area .verify_email {
  max-width: 538px;
}

.integrate-area-input {
  text-align: left;
}

.form-supported {
  margin: 0 auto;
  text-align: center;
  padding-top: 32px;
  min-width: 1150px;
  max-width: 1150px;
  width: 100%;
}

.ns-img img {
  width: auto;
}

.not-supported-area .verify-title .icon-mail {
  height: 2.5rem;
}

.fw-wide .input-group {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
}

.fw-wide .input-group .form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fw-wide .form-input {
  padding: 0 24px 24px;
}

.fw-wide .input-group .button {
  margin-left: -1px;
  height: 40px;
  min-width: 113px;
  border: 1px solid var(--btn-background);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}

.fw-wide .input-group .button:hover {
  border: 1px solid #f3e0d2;
  border-left: 0;
}

.light-item svg,
.light-item label {
  opacity: 0.3;
}

.fw-wide .form-area .confirm-setting-area {
  margin-bottom: 8px;
  background: #ffffff;
}

.fw-wide .alert-success {
  color: #219653;
  background-color: #bff1d5;
  border-color: #bff1d5;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  padding: 1rem;
  margin-bottom: 0;
  border: 1px solid transparent;
  border-radius: 4px;
  line-height: 16px;
  width: 100%;
  text-align: left;
}

@media (max-width: 768px) {
  .ob-body .form-wrapper,
  .ob-body .fw-wide {
      min-width: initial;
      max-width: initial;
      width: 100%;
  }

  .ob-body .category-form-input {
      flex-direction: column;
      gap: 0.8rem;
  }
}

.logo-round {
  height: 80px;
}

.radiobox input {
  position: absolute;
  left: 100%;
  opacity: 0;
  width: 0;
  height: 0;
}

.fit-d-flex .button {
  width: 50%;
}

.button:hover {
  background: transparent;
  border-color: var(--btn-background);
  color: var(--btn-background);
}

a {
  color: var(--base-link-color);
  text-decoration: none;
}

a:hover {
  opacity: 0.8;
}

.text-left {
  text-align: left;
}

small {
  font-size: 13px;
}

.form-input {
  position: relative;
  width: 100%;
}

/*Password Show/Hide************************************************************************************/
.password-show {
  position: absolute;
  right: 1.2rem;
  top: 1.5rem;
  width: 2.2rem;
  height: 2.2rem;
  -webkit-mask-size: contain;
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  background-color: currentColor;
  -webkit-mask-image: url(../images/icons/visibility-off.svg);
  mask-image: url(../images/icons/visibility-off.svg);
  cursor: pointer;
  outline: none;
  z-index: 1;
  color: var(--navy);
  opacity: 0.64;
}

.password-show.active {
  opacity: 1;
  -webkit-mask-image: url(../images/icons/visibility.svg);
  mask-image: url(../images/icons/visibility.svg);
}

.security_info .form-input {
  position: relative;
  width: 100%;
}

/*Tooltip*/
.tooltip {
  position: relative;
  z-index: 1;
}

.tooltip-icon {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 1.4rem;
  height: 1.4rem;
  color: var(--color-white);
  padding: 0.1rem;
  border-radius: 100%;
}

.tooltip-icon:before {
  content: '';
  position: absolute;
  z-index: 0;
  width: 0.7rem;
  height: 0.7rem;
  background: var(--color-dark);
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 100%;
}

.tooltip-icon-danger {
  color: var(--color-red);
  padding: 0;
  background: transparent !important;
}

.tooltip-icon-orange {
  color: var(--color-orange);
  padding: 0;
  background: transparent !important;
}

.tooltip-icon-danger:before {
  display: none;
}

.tooltip-icon-orange:before {
  display: none;
}

.tooltip-icon .icon {
  position: relative;
  z-index: 1;
}

.tooltip_text {
  position: absolute;
  border-radius: 0.4rem;
  background: var(--color-navy);
  color: var(--color-white);
  padding: 0.8rem;
  font-weight: 400;
  font-size: 1.1rem;
  z-index: 2;
  top: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  width: max-content;
  max-width: 22.4rem;
  text-align: left;
}

.tooltip:hover {
  z-index: 2;
}

.tooltip:hover .tooltip-icon {
  background: var(--color-white);
  color: var(--color-navy);
}

.tooltip:hover .tooltip-icon:before {
  display: none;
}

.tooltip:hover .tooltip_text {
  opacity: 1;
  visibility: visible;
}

.password-tooltip .tooltip-icon {
  visibility: hidden;
}

.password-tooltip .tooltip_text {
  opacity: 1;
  visibility: visible;
  padding: 16px;
  max-width: 260px;
}

.password-tooltip .tooltip_text .pwd-info-title {
  font-weight: 700;
  font-size: 12px;
  border-bottom: 0.8px dashed rgba(255, 255, 255, 0.3);
  line-height: 14px;
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.icon-spacing {
  gap: 5px;
}

.password-tooltip .tooltip_text .pwd-info label {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
}

.password-tooltip .tooltip {
  position: absolute;
  right: -65px;
  top: -5px;
  z-index: 0;
}

.pwd-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tooltip-arrow {
  background: #354253;
  width: 15px;
  height: 15px;
  position: absolute;
  left: -7px;
  transform: rotate(45deg);
  border-radius: 2px;
}

.bottom-notify {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  background: rgba(51, 51, 51, 0.64);
  color: var(--color-white);
  padding: 1.6rem 0;
  text-align: center;
  transition: all 0.5s ease-in;
  bottom: -10rem;
}

.bottom-notify.active {
  bottom: 0;
}

.bottom-notify-close {
  position: absolute;
  right: 1.6rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
  z-index: 999;
}

.bottom-notify-close .icon {
  width: 100%;
  height: 100%;
}

.fp-text {
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  color: #354253;
  font-weight: 400;
  font-size: 16px;
  margin-top: 24px;
}

.pointer-none {
  pointer-events: none;
}

.ob-body .form-input input[type='text'].pointer-none {
  opacity: 0.7;
}

.alert-error {
  color: #d33e3e;
  background-color: #fad7d7;
  border-color: #fad7d7;
  font-weight: 400;
  position: relative;
  padding: 1rem;
  margin-bottom: 0;
  border: 1px solid transparent;
  border-radius: 4px;
  line-height: 16px;
  width: 100%;
  text-align: left;
}

.fs-13 {
  font-size: 13px;
}

.alert-height {
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/*Onbording dev***********************************************************************************************/
:root {
  --phone: 480px;
  --tablet: 768px;
  --desktop: 980px;
  --widescreen: 1200px;
  --color-white: #fff;
  --color-offwhite: #ede7d8;
  --color-black: #000;
  --color-navy: #354253;
  --color-dark: #23272a;
  --color-grey-1: #333;
  --color-grey-2: #4f4f4f;
  --color-grey-3: #828282;
  --color-grey-4: #bdbdbd;
  --color-grey-5: #e0e0e0;
  --color-grey-6: #f2f2f2;
  --color-grey-7: #f9f9f9;
  --color-blue: #39f;
  --color-blue-dark: #6953e7;
  --color-red: #eb5757;
  --color-green-1: #219653;
  --color-yellow: #f2c94c;
  --color-orange: #f2994a;
  --color-orange-100: #0E3279;
  --color-orange-tint-80: #cd8249;
  --color-orange-tint-70: #d18d58;
  --color-orange-tint-50: #daa277;
  --color-orange-tint-40: #deac86;
  --color-orange-tint-30: #e2b695;
  --color-orange-tint-20: #e6c1a4;
  --color-orange-tint-10: #eacbb3;
  --color-orange-tint-6: #f3e0d2;
  --color-orange-tint-4: #f7eae1;
  --color-orange-tint-2: #fbf5f0;
  --color-orange-tint-1: #fffcf9;
  --color-orange-shade-100: #0E3279;
  --color-orange-shade-90: #af6730;
  --color-orange-shade-80: #a15f2c;
  --color-orange-shade-70: #945728;
  --color-orange-shade-60: #864f25;
  --color-orange-shade-50: #794721;
  --color-orange-shade-40: #794721;
  --color-orange-shade-30: #5e371a;
  --color-orange-shade-20: #512f16;
  --color-orange-shade-10: #432812;
  --color-orange-shade-8: #36200f;
  --color-orange-shade-6: #28180b;
  --color-orange-shade-4: #1b1007;
  --color-orange-shade-2: #0d0804;
  --font-sans-serif: 'Roboto', sans-serif;
  --font-serif: 'Times New Roman', Times, Baskerville, Georgia, serif;
  --base-background-color: var(--color-white);
  --base-font-family: var(--font-sans-serif);
  --base-font-size: var(--text);
  --base-line-height: 1.42;
  --base-text-color: var(--color-navy);
  --base-border-radius: 0.4rem;
  --base-min-width: 320px;
  --gap: 15px;
  --base-link-color: var(--color-orange-100);
  --btn-color: var(--color-white);
  --btn-background: var(--color-orange-100);
  --btn-background-active: var(--color-white);
  --btn-height: 2.5em;
  --btn-radius: 0.333em;
  --btn-padding: 0.5em 1em;
  --btn-font-size: 1.2rem;
  --btn-font-family: var(--font-sans-serif);
  --form-font-family: var(--font-sans-serif);
  --form-font-size: 1.4rem;
  --form-element-padding: 0.35714285714em 0.57142857142em;
  --form-element-border-color: var(--color-white);
  --form-element-focus-border-color: var(--color-orange-100);
  --placeholder-color: #808084;
  --headings-font-family: var(--font-sans-serif);
  --headings-color: var(--base-text-color);
  --headings-sizes-h1: 2.4rem;
  --headings-sizes-h2: 2rem;
  --headings-sizes-h3: 1.8rem;
  --headings-sizes-h4: 1.6rem;
  --headings-sizes-h5: 1.4rem;
  --headings-sizes-h6: 1.2rem;
  --text: 1.4rem;
  --h-height-top: 5.2rem;
  --h-height-bottom: 4rem;
  --h-height: calc(var(--h-height-bottom) + var(--h-height-top));
  --color-bluewood: #283b49;
}

body {
  font: var(--base-font-size) / var(--base-line-height)
      var(--base-font-family) !important;
  font-weight: 400;
  overflow-anchor: none;
}

h1 {
  font-size: 2.4rem !important;
  margin: 0 !important;
}

small {
  font-size: 13px !important;
}

.info-integrate {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 14px;
  color: #808084;
  display: block;
  width: 100%;
}

.menu_link:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% + 1rem);
  border-radius: 0.8rem 0.8rem 0 0;
  background: var(--m-bg-active);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
}

.menu_1 {
  --m-gap: 1.1rem 1.6rem;
}

.password-icon-top {
  top: 1.5rem !important;
}

.link-color {
  color: var(--base-link-color) !important;
}

.retail-pos {
  h4 {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      width: 100%;
      margin-bottom: 16px;
  }
  .select_info-integrate .radiobox input:checked ~ .radiobox-text,
  .select_info-integrate .radiobox .radiobox-text {
      border: none;
  }
  .select_info-integrate .radiobox .radiobox-text {
      opacity: 1;
  }
  .select_info-integrate {
      flex-wrap: wrap;
  }
  .radiobox-group .radiobox {
      width: 49%;
  }
}

.form-control {
  font-size: 1.4rem;
  line-height: 1.15;
}

.auth-sign-in-form {
  border-radius: 10px;
    border: 1px solid #D4D4D4;
    background: white;
}