@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;0,1000;1,200;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;0,1000;1,200;1,300;1,400;1,500&display=swap");
/*# sourceMappingURL=index.css.map */

.chatbot-container {
  position: fixed;   
  bottom: 20px;      
  right: 20px;       
  z-index: 9999;   
  min-width: 45px;
  min-height: 45px;  
  max-width: 400px;      
  max-height: 600px;     
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
}
